// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Investment',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Investment',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Create Investment', path: PATH_DASHBOARD.investment.create },
          { title: 'View Investment', path: PATH_DASHBOARD.investment.view }
        ]
      },

      // WITHDRAWAL : BLOG
      {
        title: 'Withdrawals',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'Create Withdrawal', path: PATH_DASHBOARD.withdrawal.create },
          { title: 'View Withdrawal', path: PATH_DASHBOARD.withdrawal.view },
          { title: 'Raffle Withdrawal', path: PATH_DASHBOARD.withdrawal.raffle }
        ]
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'Contact',
        path: PATH_DASHBOARD.contact.root,
        icon: ICONS.mail,
        admin: false
      },
      { title: 'Account', path: PATH_DASHBOARD.user.account, icon: ICONS.chat }
    ]
  }
];

export default sidebarConfig;
