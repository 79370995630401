import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Navigate } from 'react-router';
import { baseUrl, firebaseConfig } from '../config';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['charokgb@gmail.com'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  firebase.firestore();
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isEmailVerified: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isEmailVerified: user?.emailVerified || false,
      user
    };
  }
  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUser: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [user, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(
    () =>
      firebase?.auth().onAuthStateChanged((user) => {
        if (user) {
          const docRef = firebase.firestore().collection('users').doc(user.uid);
          docRef
            .get()
            .then((doc) => {
              if (doc) {
                setProfile(doc.data());
              }
            })
            .catch((error) => {
              console.error(error);
            });

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        if (!res.user.emailVerified) {
          return <Navigate to="/auth/verify" />;
        }
      });
  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const updateUser = (values) => {
    firebase
      .auth()
      .currentUser.updateProfile(values)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    const docRef = firebase.firestore().collection('users').doc(auth.uid);
    return docRef
      .update(values)
      .then((doc) => {
        console.log(values, auth, doc);

        if (doc) {
          setProfile(values);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (email, password, firstName, lastName, referralCode) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            firstName,
            lastName,
            referralCode
          })
          .then((res) => {
            const actionCodeSettings = {
              url: `${baseUrl}/auth/verify?email=${email}`
            };
            firebase
              .auth()
              .currentUser.sendEmailVerification(actionCodeSettings)
              .then(() => {});
          });
      });

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || auth?.photoURL,
          firstName: user?.firstName,
          lastName: user?.lastName,
          displayName: auth.displayName || `${user?.firstName} ${user?.lastName}` || false,
          role: ADMIN_EMAILS.includes(auth.email) ? 'admin' : 'user',
          phoneNumber: auth.phoneNumber || user?.phoneNumber || '',
          country: user?.country || '',
          address: user?.address || '',
          state: user?.state || '',
          city: user?.city || '',
          zipCode: user?.zipCode || '',
          about: user?.about || '',
          wallet_address: user?.wallet_address || '',
          emailVerified: auth.emailVerified,
          created_at: auth?.created_at,
          patron: user?.patron
        },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
